#small-screen-error-alert {
  display: none;
}

@media (max-height: 400px), (max-width: 1000px) {
  #small-screen-error-alert {
    display: block;
  }
}

#small-screen-error-alert-cover {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: white;
}

#small-screen-error-alert-error-message-container {
  height: 38vh;
  transform: translateY(150%);
}
