#icon-mode-panel {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 81px;
  border-radius: 8px;
  padding-top: 8px;
  margin-top: -18vh;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
}

#icon-mode-panel-description-text {
  user-select: none;
  padding-bottom: 9px;
}

/* when screen is narrow - move panel higher */
@media (max-height: 674px) {
  #icon-mode-panel {
    margin-top: -19vh;
  }
}

/* when screen is narrow - move panel higher, decrease height and condense text with icons */
@media (max-height: 600px) {
  #icon-mode-panel {
    margin-top: -20vh;
    height: 77px;
  }
  #icon-mode-panel-description-text {
    padding-bottom: 6px;
  }
}

/* when screen is narrow - move panel higher */
@media (max-height: 538px) {
  #icon-mode-panel {
    margin-top: -22vh;
  }
}

/* when screen is narrow - remove text and adjust panel size, position and content padding */
@media (max-height: 470px) {
  #icon-mode-panel {
    padding-top: 10px;
    margin-top: -19.5vh;
    height: 49px;
  }
  #icon-mode-panel-description-text {
    display: none;
  }
}

/* when screen is narrow - move panel to upwards */
@media (max-height: 400px) {
  #icon-mode-panel {
    margin-top: -20vh;
  }
}

/* when screen is narrow - remove all panel contents */
/* the justification for this is that the likely users of such interface are going to be mobile users who will not have the ability
   to upload svg files or carry the intent to test their svg image against the generated filter result */
@media (max-height: 380px), (max-width: 1000px) {
  #icon-mode-panel {
    display: none !important;
  }
}

#icon-mode-upload-icon-button {
  background-color: #1160a2;
  height: 38px;
  min-width: 45px;
}

#icon-mode-user-icon-container {
  vertical-align: middle;
  opacity: 0;
}
