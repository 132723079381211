#switch-button-container {
  position: absolute;
  width: 100%;
}

#switch-button {
  background-color: white;
  width: 36px;
  max-height: 32px;
  min-width: 10px;
  font-size: 20px;
  cursor: pointer;
  margin-top: 60px;
}

/* when screen size gets too small - move switch button close to the convert button */
@media (max-height: 360px), (max-width: 1000px) {
  #switch-button {
    margin-top: 7vh;
  }
}

#switch-button:hover {
  background-color: rgb(238, 238, 238);
}

#switch-button-icon {
  height: 22px;
}
