#color-picker-button {
  margin-left: 18px;
  margin-top: 1px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-width: 0;
  border-radius: 10px;
  border: 2px solid #dedede;
  outline: none;
}

#color-picker-panel {
  border: 1px #c0c0c0;
  border-style: solid;
  width: fit-content;
  background-color: #b2b2b2;
  border-radius: 9px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 5px 9px 0px rgb(186 186 186);
  top: 38px;
  margin-left: -2px;
}

/* when screen width is narrow - move color picker closer to the center of the screen to allow the user to fully see it */
@media (max-width: 822px) {
  #color-picker-panel {
    margin-left: -40px;
  }
}

@media (max-width: 748px) {
  #color-picker-panel {
    margin-left: -70px;
  }
}

@media (max-width: 690px) {
  #color-picker-panel {
    margin-left: -100px;
  }
}

@media (max-width: 630px) {
  #color-picker-panel {
    margin-left: -130px;
  }
}

@media (max-width: 600px) {
  #color-picker-panel {
    margin-left: -160px;
  }
}

@media (max-width: 570px) {
  #color-picker-panel {
    margin-left: -190px;
  }
}

@media (max-width: 540px) {
  #color-picker-panel {
    margin-left: -190px;
  }
}

@media (max-width: 510px) {
  #color-picker-panel {
    margin-left: -220px;
  }
}

@media (max-width: 480px) {
  #color-picker-panel {
    margin-left: -230px;
  }
}
