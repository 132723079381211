.column {
  height: 100%;
  float: left;
  position: relative;
}

.column:nth-child(1) {
  width: calc(50% - 52px);
}

.column:nth-child(2) {
  width: 104px;
}

.column:nth-child(3) {
  width: calc(50% - 52px);
}

/* when screen width is narrow - stack columns vertically instead of horizontally */
@media (max-width: 1000px) {
  .column:nth-child(1) {
    top: 30px;
    width: 100%;
    height: calc(50% - 52px);
  }

  .column:nth-child(2) {
    width: 100%;
    height: 70px;
  }

  .column:nth-child(3) {
    width: 100%;
    height: calc(50% - 10px);
  }
}

.column-contents {
  position: absolute;
  top: 47%;
  left: 50%;
  margin: 0;
  width: 100%;
  margin-top: -15vh;
  transform: translate(-50%, -50%);
}

/* when screen is narrow - move content starting from convert button bottomwards closer to the bottom */
@media (max-height: 525px) and (max-width: 1000px) {
  .column-contents {
    margin-top: -10vh;
  }

  .column:nth-child(2) {
    height: 104px;
  }

  .column:nth-child(3) {
    height: calc(50% - 52px);
  }
}

/* when screen width is narrow and history component is no longer displayed - move column contents to vertical center of the screen */
@media (max-height: 380px), (max-width: 1000px) {
  .column-contents {
    margin-top: 0px;
  }
}
