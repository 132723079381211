#header {
  width: 100%;
  position: absolute;
  /* needs to be higher than then input column so that the color picker panel would not cover it */
  z-index: 3;
  /* this is used to prevent the header from covering underlying content and not allowing the user
     to select it, the contained icons will still be displayed as normal */
  height: 0px;
}

/* when screen height is narrow - remove the header element */
@media (max-height: 256px) {
  #header {
    display: none;
  }
}

.header-content {
  user-select: none;
}

.generic-header-logo {
  width: 23px;
  height: 23px;
  float: right;
  margin-right: 10px;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(32%) sepia(13%) saturate(0%) hue-rotate(187deg) brightness(97%) contrast(92%);
}

.generic-header-logo:hover {
  filter: brightness(0);
}

#css-filter-converter-logo {
  float: left;
  height: 80px;
  margin-top: 2px;
  margin-left: 4px;
}

#css-filter-converter-logo-text {
  float: left;
  font-size: 19px;
  font-family: 'Yu Gothic UI';
}

#css-filter-converter-logo-container {
  float: left;
  margin-top: 2px;
  margin-left: 2px;
}

#css-filter-converter-logo-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 20.5px;
  letter-spacing: 0.7px;
  font-weight: 600;
  transform: scale(0.94, 0.98);
  float: left;
}

#css-filter-converter-logo-icon {
  width: 44px;
  margin-top: 5px;
  margin-left: -6px;
  float: left;
}

#github-logo {
  margin-top: 6px;
}

#npm-logo {
  width: 46px;
  height: 18px;
  float: right;
  margin-right: 15px;
  cursor: pointer;
  margin-top: 10px;
}

/* when screen is narorow - remove the icon dropdown item */
/* this is also enforced in the iconModePanel.css file */
@media (max-height: 380px), (max-width: 1000px) {
  #icon-mode-dropdown-item {
    display: none;
  }
}
