#history {
  position: absolute;
  width: 100vw;
  /* height: calc(65vh - 75px); */
  /* height: 49vh; */
  height: calc(55vh - 75px);
  display: flex;
  flex-flow: column wrap;
  overflow: hidden;
  transition: opacity 1s 0.2s, margin-top 1s 0.2s;
}

/* when screen is narrow - do not display history */
@media (max-height: 380px), (max-width: 1000px) {
  #history {
    display: none;
  }
}

.history-row {
  margin-top: 10px;
  width: 100vw;
  color: grey;
}

.history-hidden {
  opacity: 0;
  margin-top: 0px;
}

.history-visible {
  opacity: 1;
  margin-top: 50px;
}
