#background-icons-container {
  width: 100vw;
  height: calc(55vh - 75px);
  top: 90px;
  position: absolute;
  left: calc(-50vw - 52px);
  display: grid;
  grid-template-columns: 50% 50%;
}

@media (max-height: 450px) {
  #background-icons-container {
    top: 40px;
  }
}

@media (max-height: 380px), (max-width: 1000px) {
  #background-icons-container {
    display: none !important;
  }
}

.background-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  opacity: 0.04;
}
