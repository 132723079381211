#error-alert {
  position: absolute;
  width: max-content;
  max-width: 80vw;
  margin-top: -18vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

#error-alert-text {
  color: rgb(95, 33, 32);
}
