.result-header-text {
  user-select: none;
}

.prefix-result-header-text {
  margin-right: 10px;
}

.no-result-prefix-header-text {
  margin-top: -12px;
  margin-left: -12%;
}

/* when screen is narrow - display no result prefix text in the middle of the result column */
@media (max-width: 1000px) {
  .no-result-prefix-header-text {
    margin-left: 0;
  }
}
